<template>
  <div class="page-login">
    <div class="header">
      <div class="desc">
        <h1 class="title">Login</h1>
        <p>v1.0.0.00</p>
      </div>
      <div class="logo">
        <img src="../../assets/images/logo.png" alt="" />
      </div>
    </div>
    <div class="content">
      <h2 class="title">Login</h2>
      <p>Please login to access more content</p>
      <div class="input-box">
        <span class="title">Username</span>
        <div class="input">
          <input v-model="userName" placeholder="phone/username" type="text" />
        </div>
      </div>
      <div class="input-box">
        <span class="title">Password</span>
        <div class="input">
          <input
            v-model="password"
            placeholder="Type Password"
            type="password"
          />
        </div>
      </div>

      <h3 class="tips" @click="showActionSheet">Forgot password?</h3>
      <div class="button" @click="login">Login Now</div>
      <div class="button cancel" @click="toRegister">Register Now</div>
    </div>

    <van-action-sheet
      v-model="actionSheetVisible"
      :actions="actions"
      cancel-text="Cancel"
      close-on-click-action
      @select="selectAction"
    />
  </div>
</template>
<script>
import api from "../../api/api";
export default {
  data() {
    return {
      actionSheetVisible: false,
      userName: "",
      password: "",
      actions: [
        { name: "Customer Service Whatsapp", key: "whatsapp" },
        { name: "Customer Service Telegram", key: "telegram" },
      ],
    };
  },
  created() {
    this.getCustomerList();
  },
  methods: {
    async getCustomerList() {
      const data = await api.getCustomerList({});
      this.actions = (data || []).map((item) => {
        item.name = item.title;
        return item;
      });
    },
    selectAction(item) {
      // console.log(item);
      if (item.url) {
        window.location.href = item.url;
      }
      // if (item.key === "whatsapp") {
      //   window.location.href = "";
      // } else {
      //   window.location.href = "";
      // }
    },
    showActionSheet() {
      this.actionSheetVisible = true;
    },
    onCancel() {},
    toRegister() {
      this.$router.push("/register");
    },
    async login() {
      if (!this.userName.trim()) {
        this.$toast.fail("Please type phone/username");
        return;
      }

      if (!this.password.trim()) {
        this.$toast.fail("Please type password");
        return;
      }
      let loading = "";
      try {
        loading = this.$toast.loading({
          message: "Logging...",
          forbidClick: true,
          loadingType: "spinner",
        });
        const data = await api.login({
          userName: this.userName,
          password: this.password,
        });
        loading.clear();
        this.$toast.success("Login success!");
        localStorage.setItem("token", data.token);
        localStorage.setItem("adClose", 1);
        this.$router.push("/platform/index");
      } catch (e) {
        loading.clear();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page-login {
  .header {
    width: 100%;
    height: 40vh;
    background: url(../../assets/images/nav_bg.png) no-repeat;
    background-size: cover;

    .desc {
      padding: 0 16px;
      .title {
        font-size: 18px;
        color: #fff;
        height: 36px;
        line-height: 36px;
      }

      p {
        color: #97bec3;
        font-size: 12px;
        margin: 0;
      }
    }

    .logo {
      max-width: 300px;
      width: 100%;
      margin: 50px auto 0;

      img {
        width: 100%;
      }
    }
  }

  .content {
    padding: 16px;

    h2.title {
      font-size: 20px;
      color: #27a3c7;
      padding: 16px 0;
    }

    p {
      font-size: 14px;
      color: #666;
      margin: 0;
    }

    .tips {
      font-size: 20px;
      margin-top: 32px;
      text-align: center;
      text-decoration: underline;
      font-weight: 700;
      color: #27a3c7;
    }

    .button {
      height: 50px;
      line-height: 50px;
      text-align: center;
      background-image: linear-gradient(90deg, #25abc4, #1acbb5);
      background-size: cover;
      color: #fff;
      margin: 20px auto;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 700;

      &.cancel {
        width: 60%;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        font-weight: 400;
        background: linear-gradient(143deg, #5ffe99 0%, #5ffef9 100%);
        color: #000;
      }
    }
  }
}
</style>