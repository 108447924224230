<template>
  <div class="cert-page">
    <my-header title="Certificate"></my-header>
    <div class="cert-content">
      <img @click="previewImage" src="../../assets/images/cert.png" alt="" />
    </div>
  </div>
</template>
<script>
import MyHeader from "../../components/header.vue";
export default {
  components: { MyHeader },
  methods: {
    previewImage() {},
  },
};
</script>
<style lang="less" scoped>
.cert-page {
  padding-top: 90px;

  .cert-content {
    padding: 0 24px 24px;

    img {
      max-width: 100%;
    }
  }
}
</style>