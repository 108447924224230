import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/Login.vue'
import Register from '../views/login/Register.vue'
import Agreement from '../views/login/Agreement.vue'
import Platform from '../views/home/Platform.vue'
import Index from '../views/home/Index.vue'
import Cert from '../views/home/Cert.vue'
import VipLevel from '../views/home/VipLevel.vue'
import WithDraw from '../views/home/Withdraw.vue'
import WithDrawHistory from '../views/home/WithdrawHistory.vue'
import Deposit from '../views/home/Deposit.vue'
import Profile from '../views/home/Info.vue'
import Transaction from '../views/home/Transaction.vue'
import Start from '../views/start/Index.vue'
import Records from '../views/records/Index.vue'
import changeLoginPassword from '../views/home/ChangeLoginPassword.vue'
import changeSecurityPassword from '../views/home/ChangeSecurityPassword.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'DefaultPage',
    component: Login
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: Agreement
  },
  {
    path: '/platform',
    name: 'Platform',
    component: Platform,
    children: [
      {
        path: 'index',
        name: 'index',
        component: Index,
        meta: {
          includeTabBar: true
        }
      },
      {
        path: 'cert',
        name: 'cert',
        component: Cert,
      },
      {
        path: 'tc',
        name: 'tc',
        component: Agreement,
      },
      {
        path: 'event',
        name: 'event',
        component: Agreement
      },
      {
        path: 'faq',
        name: 'faq',
        component: Agreement
      },
      {
        path: 'about',
        name: 'about',
        component: Agreement
      },
      {
        path: 'vipLevel',
        name: 'vipLevel',
        component: VipLevel,
      },
      {
        path: 'withdraw',
        name: 'withdraw',
        component: WithDraw,
      },
      {
        path: 'withdraw-history',
        name: 'withdraw-history',
        component: WithDrawHistory,
      },
      {
        path: 'deposit',
        name: 'deposit',
        component: Deposit,
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
      },
      {
        path: 'transaction',
        name: 'transaction',
        component: Transaction,
      },
      {
        path: 'changePwd',
        name: 'changePwd',
        component: changeLoginPassword,
      },
      {
        path: 'changeSecurityPwd',
        name: 'changeSecurityPwd',
        component: changeSecurityPassword,
      },
      {
        path: 'start',
        name: 'start',
        component: Start,
        meta: {
          includeTabBar: true
        }
      },
      {
        path: 'records',
        name: 'records',
        component: Records,
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const isAuthenticated = (to) => {
  if (routes.some(item => item.path === to.path)) {
    return false
  } else {
    return !localStorage.getItem('token')
  }

}

router.beforeEach((to, from, next) => {
  // 在这里执行你的拦截逻辑

  // 检查用户是否已登录
  if (isAuthenticated(to)) {
    // 如果未登录，则重定向到登录页
    next('/login')
  } else {
    // 如果已登录，则继续路由切换
    next()
  }
})

export default router
