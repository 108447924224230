<template>
  <div class="records-page">
    <my-header title="Records"></my-header>

    <div class="nav-bar">
      <div
        class="nav-item"
        v-for="(item, index) in navList"
        :key="index"
        :class="{ active: index === currentIndex }"
        @click="changeIndex(index)"
      >
        {{ item }}
      </div>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="no more..."
      loading-text="Loading..."
      @load="more"
    >
      <div class="task-list">
        <div class="task-item" v-for="(item, index) in taskList" :key="index">
          <div class="task-title">
            {{ item.time }}
            <div
              class="label"
              :class="{
                yellow: item.ordeerStatus === 0,
                green: item.ordeerStatus === 1,
                red: item.isConnected === 1 && item.ordeerStatus !== 1,
              }"
              @click="toTips(item)"
            >
              {{
                item.isConnected === 1 && item.ordeerStatus !== 1
                  ? "Pending"
                  : statusList[item.ordeerStatus]
              }}
            </div>
          </div>

          <div class="task-main">
            <div class="task-info">
              <img class="image" :src="item.goodsImg" alt="" />
              <h3 class="title">{{ item.goodsName }}</h3>
            </div>
            <div class="price-info">
              <div class="price-item">
                <h4 class="title">Total amount</h4>
                <p>USDT {{ item.goodsPrice }}</p>
              </div>
              <div class="price-item">
                <h4 class="title">Profit</h4>
                <p>USDT {{ item.income }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import api from "@/api/api";
import MyHeader from "../../components/header.vue";
import { Dialog } from "vant";
import dayjs from "dayjs";
export default {
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: { MyHeader },
  data() {
    return {
      navList: ["ALL", "Submission", "Completed", "Pending"],
      statusList: ["Submit Now!", "Completed"],
      currentIndex: 0,
      taskList: [],
      loading: false,
      finished: false,
      currentPage: 0,
    };
  },
  methods: {
    more() {
      this.currentPage += 1;
      this.getOrderList();
    },
    async getOrderList() {
      const params = {
        pageNum: this.currentPage,
        pageSize: 10,
      };

      if (this.currentIndex === 3) {
        params.isConnected = 1;
        params.ordeerStatus = 0;
      }

      if (this.currentIndex === 1 || this.currentIndex === 2) {
        params.ordeerStatus = this.currentIndex - 1;
        params.isConnected = 0;
      }

      const data = await api.getOrderList(params);
      this.taskList = this.taskList.concat(
        data.map((item) => {
          item.time = dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss");
          return item;
        })
      );
      this.loading = false;
      this.finished = data.length !== 10;
    },
    changeIndex(index) {
      this.finished = false;
      this.currentIndex = index;
      this.taskList = [];
      this.currentPage = 1;
      this.loading = true;
      this.getOrderList();
    },
    toTips(item) {
      if (item.ordeerStatus !== 0) return;
      Dialog.confirm({
        title: "Tips",
        message: "Optimize the data?",
        confirmButtonText: "Sure",
        cancelButtonText: "Cancel",
        confirmButtonColor: "rgb(38, 170, 195)",
        cancelButtonColor: "#323233",
      })
        .then(async () => {
          try {
            const data = await api.submitOrder({ id: item.id });
            console.log(data);
            if (data && data.code === 508) {
              this.$dialog
                .alert({
                  title: "Tips",
                  message: `Current Balance: ${(
                    this.userInfo.balance || 0
                  ).toFixed(2)}, Please recharge!`,
                  confirmButtonText: "Sure",
                })
                .then(() => {
                  this.$router.push("/platform/deposit");
                });
            } else {
              this.$toast.success("Optimize is successful!");
              this.changeIndex(this.currentIndex);
            }
          } catch (e) {
            console.log(e);
          }
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>
<style lang="less" scoped>
.records-page {
  padding-top: 90px;

  .nav-bar {
    padding: 0 10px 10px;
    border-bottom: 2px solid #1dc1b9;
    display: flex;
    justify-content: space-between;

    .nav-item {
      text-align: left;
      border-bottom: 3px solid #fff;
      padding: 5px;
      color: #727b8c;
      font-size: 15px;
      line-height: 1.4;

      &.active {
        font-weight: 700;
        color: #23b5c0;
        border-bottom: 3px solid #23b5c0;
      }
    }
  }

  .task-list {
    padding: 16px;
    .task-item {
      margin-bottom: 10px;

      .task-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        color: #888;

        .label {
          height: 22px;
          line-height: 18px;
          border-radius: 4px;
          text-align: center;
          font-size: 12px;
          box-sizing: border-box;
          padding: 0 8px;

          &.yellow {
            border: 2px solid #facc01;
            background: #facc01;
            color: #333;
          }

          &.green {
            border: 2px solid #58c069;
            border-radius: 4px;
            color: #58c069;
          }

          &.red {
            border: 2px solid #ee0a24;
            border-radius: 4px;
            color: #ee0a24;
            background-color: #fff;
          }
        }
      }
      .task-main {
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 10px;
        margin-top: 5px;
        box-shadow: 0 2px 2px #ddd;

        .task-info {
          border-bottom: 1px solid #c7efed;
          display: flex;
          padding-bottom: 10px;

          .image {
            width: 65px;
            height: 65px;
          }

          h3.title {
            flex: 1;
            margin-left: 15px;
            font-size: 14px;
            line-height: 1.6;
            margin-top: 5px;
          }
        }

        .price-info {
          display: flex;
          padding-top: 15px;
          line-height: 1.6;

          .price-item {
            flex: 1;

            h4.title {
              font-size: 12px;
              color: #777;
              margin: 0;
              font-weight: 400;
            }

            p {
              font-weight: 700;
              margin-top: 5px;
              color: #4cc2d3;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

.van-dialog .van-dialog__header {
  padding-top: 10px;
  line-height: 24px;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: #4bbace;
}
</style>